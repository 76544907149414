<template>
  <main :class="{ 'padding-webview': checkWebview(), 'padding-has-caution': isHasCaution }">
    <div class="exchange-service-common">
      <!-- TITLE SERVICE -->
      <section class="intro-service container">
        <!-- INFO SERVICE -->
        <h3 class="sp title-name">Gift Pad</h3>
        <div class="title-left">
          <img src="../../../assets/images/gif-pad.png" alt="logo">
          <div class="info">
            <h3 class="pc title-name">Gift Pad</h3>
            <p><span>◆ギフトパッドとは？</span><br>
              数百点以上ある商品ラインナップの中から<br>
              お好きな商品を1つ、自由に選べる電子カタログギフトです。<br>
              16桁のギフトコード付きURLを家族や知人に贈り、<br>
              ギフトとしてお渡しすることもできます。<br>
              <br>
              商品ラインナップはこちら↓<br>
              ▼5,000円コース<br>
              <a @click.prevent="openUrl('https://giftpad.jp/receive/goods_sample/index/3413020100cc')" class="text-green">https://giftpad.jp/receive/goods_sample/index/3413020100cc</a><br>
              <br>
              ▼10,000円コース<br>
              <a @click.prevent="openUrl('https://giftpad.jp/receive/category_sample/index/3424020400ce')" class="text-green">https://giftpad.jp/receive/category_sample/index/3424020400ce</a><br>
              <br>
              ▼15,000円コース<br>
              <a @click.prevent="openUrl('https://giftpad.jp/receive/goods_sample/index/4733021200d')" class="text-green">https://giftpad.jp/receive/goods_sample/index/4733021200d2</a>
            </p>
          </div>
        </div>

        <!-- RULES SERVICE -->
        <ul class="rules-service">
          <li>
            <span>交換日数</span>
            <p><b>{{ pointExchangeService.exchange_date }}</b></p>
          </li>
          <li>
            <span>交換手数料</span>
            <p><b>無料</b></p>
          </li>
          <li>
            <span>交換レート</span>
            <strong>1pt → <span>１円相当</span></strong>
          </li>
          <li>
            <span>最低交換ポイント</span>
            <p class="point">5,550pt</p>
          </li>
        </ul>
      </section>

      <!-- NOTE SERVICE -->
      <section class="note-service d-flex">
        <div class="container has-text-indent d-inline-block">
          <p class="text-indent">※一回の交換にシステム利用料＋送料として550ptが発生します。表示されている交換ポイント数は上記の550ptを含んでおります。</p>
            <br>
          <p style="margin-left: -1rem">～商品交換の流れ～<br>
            ①お手元に届いたギフトURLにアクセス<br>
            ②「ギフトを受け取る」をクリック<br>
            ③様々な商品カテゴリーが表示されますので、お好きな商品をお選びください<br>
            ④お届け先ご住所や必要事項等をご入力いただきましたら申込完了です
          </p>
        </div>
      </section>

      <!-- EXCHANGE POINT -->
      <div class="control-exchange">
        <!-- LIST POINT -->
        <div class="list-point-exchange container"
             v-if="profile && token">
          <h3>交換ポイント選択</h3>

          <ul>
            <li v-for="(item, index) in listPoint"
                @click="choosePoint(item.value, item.param)"
                :class="{ 'disabled' : user_point.total_point_amount < item.value }"
                :key="index">
              {{ item.value | numberWithCommas }} pt
            </li>
          </ul>
        </div>

        <!-- BTN redirect to login -->
        <button v-else @click="openPageNative('Login')"
                class="btn-exchange-point btn-redirect">
          すでに会員の方はこちらからログイン
        </button>

        <!-- BTN BACK -->
        <div class="link-back">
          <router-link class="btn-back"
                       to="/exchange-point">
            <span>
               <ChevronLeftIcon size="20" />ポイント交換先一覧へ戻る
            </span>
          </router-link>
        </div>
      </div>

      <!-- LIST QA -->
<!--      <div class="wrap-list-QA">-->
<!--        <QuestionAnswer class="list-QA container"-->
<!--                        :list-question-answer="EXCHANGE_POINT_QA"/>-->
<!--      </div>-->
    </div>

    <!-- MODAL CONFIRM EXCHANGE POINT -->
    <Modal @close-modal="flagModalConfirm = false"
           class="modal-exchange-point"
           v-if="flagModalConfirm">
      <div class="content-body"
           slot="body">
        <h3 class="mb-1">ポイント交換先<br class="sp"> Gift Pad</h3>
        <p class="sub-title mb-4">Gift Padへの交換となります。</p>
        <div class="point-exchange mb-3">
          <span>交換</span>
          <strong>{{ pointChoose | numberWithCommas }}<span>ptギフト</span></strong>
          <span class="pc">ptギフト</span>
        </div>
        <p class="text-instruct mb-4">交換申請するボタンをクリックするとポイント交換が完了となります。</p>
        <p class="note">※ギフトコードの有効期限は、発行日から6ヵ月以内になります。有効期限を過ぎると失効いたしますのでご注意ください。<br>
          ※商品の発送は申込日から最大で3週間ほど頂戴しております。<br>
          ※配達期間指定の記載がある商品については、それぞれ表示された日（または期間）にお届けいたします。<br>
          ※年末年始・お盆･ゴールデンウィーク等の大型連休は、配送に通常より時間がかかる場合がございます。<br>
          ※配送先は日本国内に限ります。一部の商品に関しまして、北海道・沖縄県・離島への配送をお受けできない場合がございます。<br>
          ※配送不可地域につきましては、各商品ページに記載させていただきますので、 必ずご確認くださいますようお願い申し上げます。<br>
          ※本サービス（サンクスチケット）の利用規約は<a @click.prevent="openUrl('https://giftpad.jp/terms/')" class="color-orange">こちら</a>をご覧ください。<br>
          ※本サービス（サンクスチケット）のプライバシーポリシーは<a @click.prevent="openUrl('https://giftpad.jp/privacy_policy/')" class="color-orange">こちら</a>をご覧ください。</p>
      </div>

      <div class="btn-footer"
           slot="footer">
        <button @click="handleExchangePoint">
          <img src="../../../assets/images/icon/icon-coins.svg" alt="icon">交換申請する
        </button>
      </div>
    </Modal>

    <!-- MODAL COMPLETE EXCHANGE POINT -->
    <Modal @close-modal="handleCloseModal"
           class="modal-center modal-exchange-point"
           v-if="flagModalComplete">
      <div class="content-body"
           slot="body">
        <h3>ポイント交換先<br class="sp"> Gift Pad</h3>
        <div class="point-exchange mb-2 has-point">
          <span class="color-orange">付与ポイント</span>
          <div>
            <strong>{{ pointChoose }}<span class="color-orange sp" v-html="'ptギフト'"></span></strong>
            <time
              class="time-request"
              v-if="isExchangeAutoSuccess"
            >
              交換承認日：
              <span>
                {{ new Date() | formatDate('YYYY年MM月DD日') }}
              </span>
            </time>
          </div>
          <span class="pc color-orange"
                v-html="'ptギフト'"></span>
        </div>

        <div class="voucher" v-if="dataComplete.code">
          <span class="font-weight-bold">ギフトコード</span>
          <p class="mb-2">{{ dataComplete.code }}</p>
        </div>

        <!-- NOTE MODAL GIFTPAD -->
        <p
          class="note center"
          v-if="dataComplete.code"
        >
          ※ポイントを受け取りに行くボタンからギフトパッドに遷移いただくか、<br>
          上記のギフトコードを<a class="link-orange" href="https://giftpad.jp/login/" target="_blank">ギフトパッド</a>でご入力ください。
        </p>

        <p
          class="text-instruct manual"
          v-if="!isExchangeAutoSuccess"
        >
          ポイント交換の申請を受け付けました。<br>承認されましたら、マイページのポイント交換履歴から、ギフトの受け取りができるようになります。
        </p>
      </div>

      <div
        class="btn-footer"
        slot="footer"
        v-if="dataComplete.code"
      >
        <!-- LINK TO G-POINT -->
        <button @click.prevent="openUrl(linkGiftPad + dataComplete.code)"
                class="btn-common">
          <img src="../../../assets/images/icon/icon-coins.svg" alt="icon">ポイントを受取りに行く
        </button>
      </div>
    </Modal>

    <!-- MODAL NOTI -->
    <Modal @close-modal="turnOffNoti"
           class="modal-center modal-exchange-error"
           v-if="flagModalNoti">
      <div slot="body">
        <p v-html="messageNotification"/>

        <!-- button in modal ostiaries_error -->
        <button v-if="this.$route.meta.ostiaries_error"
                @click.prevent="handleReturn"
                v-html="(this.$route.meta.ostiaries_error === 'true') ? '番号入力画面に戻る' : '完了'"
                class="btn-into-modal"/>
      </div>
    </Modal>

    <!-- MODAL CAUTION INFO -->
    <Modal @close-modal="flagModalRedirect = false"
           class="modal-center modal-notify"
           v-if="flagModalRedirect">
          <div slot="body">
            <p>ポイント交換前に必要事項をご入力ください。</p>

            <!-- button in modal ostiaries_error -->
            <button
                    @click.prevent="handelRedirectUpdateInfo"
                    v-html="'会員情報入力'"
                    class="btn-into-modal"/>
          </div>
    </Modal>

    <!-- MODAL VERIFY AUTHEN MOBILE -->
    <ModalVerifyAuthenMobile @close-modal="flagModalVerifyAuth = false"
                              v-if="flagModalVerifyAuth"/>

    <!-- MODAL NOTIFY LINE_PAY AUTHENTICATION-->
    <!-- <ModalLinePayNotification
      :turnOffLinePayNotification="turnOffLinePayNotification"
      :isLinePayValidationError="isLinePayValidationError"
      :linePayValidationErrorMessage="linePayValidationErrorMessage"/> -->
  </main>
</template>

<script>
// import QuestionAnswer from '@/components/QuestionAnswer'
// import { EXCHANGE_POINT_QA } from '@/enum/question-answer'
import { ChevronLeftIcon } from 'vue-feather-icons'
import Modal from '@/components/advertising-detail/Modal'
import { mapActions } from 'vuex'
import Common from '@/mixins/common.mixin'
import ModalVerifyAuthenMobile from '@/components/ModalVerifyAuthenMobile'
import SNS from '@/mixins/sns.mixin'

export default {
  name: 'Index',

  mixins: [Common, SNS],

  components: {
    ModalVerifyAuthenMobile,
    Modal,
    // QuestionAnswer,
    ChevronLeftIcon
  },

  mounted () {
    // hide app's loading after page render
    if (Common.methods.checkWebview()) {
      Common.methods.triggerEventApp('PAGE_RENDERED', {})
    }
  },

  data () {
    return {
      listPoint: [
        {
          id: 1,
          value: 5550,
          param: 'dmkkk_sample-0c'
        },
        {
          id: 2,
          value: 10550,
          param: 'dmkkk_sample2-0c'
        },
        {
          id: 3,
          value: 15550,
          param: 'dmkkk_sample3-0c'
        }
      ],
      pointChoose: 5500,
      paramRequest: 'dmkkk_sample-0c',
      linkGiftPad: process.env.VUE_APP_LINK_GIFTPAD
    }
  },

  computed: {
    pointExchangePayload () {
      return { course: this.paramRequest }
    }
  },

  methods: {
    ...mapActions('exchange', ['userExchangeGiftPad']),

    choosePoint (point, param) {
      this.handleAuthenticatePhone(point, param)
    },

    async handleExchangePoint () {
      this.onExchangePoint(this.userExchangeGiftPad)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/exchange-service.scss';
.modal-exchange-point .content-body {
  h3 {
    margin-bottom: 25px;
  }
  .note {
    text-align: left;
    &.center {
      text-align: center;
    }
  }
}
</style>
